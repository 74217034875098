import { SingleEventType, getDate } from '@xborglabs/ui-shared';
import { getJoinStates } from '@xborglabs/ui-shared/dist/client';
import { useTranslation } from 'next-i18next';
import Skeleton from 'react-loading-skeleton';

import { Progressbar } from '@/modules/shared/components/Atom/Progressbar';

export type EventProgressProps = {
  progress: number;
  event?: SingleEventType;
};

export const EventProgress = ({ progress, event }: EventProgressProps) => {
  const { t } = useTranslation(['events']);

  const startRegister = t('{{val, datetime}}', {
    val: getDate(event?.registrationStartDate),
    formatParams: {
      val: {
        hour: 'numeric',
        minute: 'numeric',
        month: 'short',
        day: 'numeric',
      },
    },
  });

  const endRegister = t('{{val, datetime}}', {
    val: getDate(event?.registrationEndDate),
    formatParams: {
      val: {
        hour: 'numeric',
        minute: 'numeric',
        month: 'short',
        day: 'numeric',
      },
    },
  });

  const startDate = t('{{val, datetime}}', {
    val: getDate(event?.startDate),
    formatParams: {
      val: {
        hour: 'numeric',
        minute: 'numeric',
        month: 'short',
        day: 'numeric',
      },
    },
  });

  const endDate = t('{{val, datetime}}', {
    val: getDate(event?.endDate),
    formatParams: {
      val: {
        hour: 'numeric',
        minute: 'numeric',
        month: 'short',
        day: 'numeric',
      },
    },
  });

  const joinStates = getJoinStates(event);

  return (
    <div className="event-progress block">
      <div className="cubes">
        {/* Pre registration */}
        <div
          className={`cube-container ${
            joinStates?.isRegistrationOpen ? 'active' : 'inactive'
          }`}
        >
          <div className="cube" />
          {/* Pre registration progressbar */}
          <Progressbar
            progress={joinStates?.isRegistrationOpen ? 100 : 0}
            variant="solid"
            color="success"
            className="progress-bar"
          />
          <MobileProgress
            height={joinStates?.isRegistrationOpen ? '100%' : 0}
          />
          <div
            className={`status-detail-block${
              joinStates?.isRegistrationOpen ? ' active' : ''
            }`}
          >
            <div className="lexend-body-s active">
              {t('events:preRegister')}
            </div>
            <div className="lexend-body-xs1">
              {t('events:from')} {startRegister}
            </div>
          </div>
        </div>

        {/* Event is live */}
        <div
          className={`cube-container ${
            joinStates?.eventStarted && !joinStates?.eventEnded
              ? 'active'
              : 'inactive'
          }`}
        >
          <div className="cube" />
          <Progressbar
            variant="solid"
            color="success"
            progress={
              joinStates?.eventStarted && !joinStates?.eventEnded ? 50 : 0
            }
            className="progress-bar"
          />
          <MobileProgress
            height={
              joinStates?.eventStarted && !joinStates?.eventEnded ? '50%' : 0
            }
          />
          <div
            className={`status-detail-block${
              joinStates?.eventStarted && !joinStates?.eventEnded
                ? ' active'
                : ''
            }`}
          >
            <div
              className={`lexend-body-s${
                joinStates?.eventStarted && !joinStates?.eventEnded
                  ? ' active'
                  : ''
              }`}
            >
              {t('events:eventLive')}
            </div>
            <div className="lexend-body-xs1">
              {event ? startDate : <Skeleton className="date-placeholder" />}
            </div>
          </div>
        </div>

        {/* Event is live progressbar */}

        {/* Event has ended */}
        <div
          className={`cube-container ${
            progress >= 100 ? 'active' : 'inactive'
          }`}
        >
          <div className="cube" />

          <div
            className={`status-detail-block width-30 ${
              joinStates?.eventEnded ? ' active' : ''
            }`}
          >
            <div className="lexend-body-s">
              {joinStates?.eventEnded
                ? t('events:eventEnded')
                : t('events:eventEnds')}
            </div>
            <div className="lexend-body-xs1">
              {event ? endDate : <Skeleton className="date-placeholder" />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function MobileProgress({ height }: { height: string | number }) {
  return (
    <div className="mobile-progress-bar">
      <div style={{ height: height }} />
    </div>
  );
}
