import { SingleEventType } from '@xborglabs/ui-shared';
import { useTranslation } from 'next-i18next';

import { EventProgress, PrizePool } from '@/modules/event';
import { Card } from '@/modules/shared/components/Atom/Card/Card';

import { useEventUserRank } from '@xborglabs/ui-shared/dist/client';
import { AboutEvent } from '../AboutEvent/AboutEvent';
import classes from './EventOverview.module.scss';

export type EventOverviewProps = {
  percentage: number;
  event?: SingleEventType;
};

export const EventOverview = ({ percentage, event }: EventOverviewProps) => {
  const { t } = useTranslation(['events', 'globals', 'quests']);
  const { data: myRank } = useEventUserRank(event?.eventId);
  return (
    <div className={classes.event_overview}>
      <AboutEvent event={event} />

      <div className={classes.event_body}>
        <Card
          variant="overlay"
          className={`flex column expand content ${classes.progress}`}
        >
          <EventProgress progress={percentage} event={event} />
        </Card>
        <Card variant="overlay" className={classes.prizepool}>
          {event?.rewards && (
            <>
              <div className={classes.prize_header}>
                <h4 className="font-bold lexend-heading-l">
                  {t('events:prizepool')}
                </h4>
                <span className={`${classes.my_rank} lexend-body-s`}>
                  {t('quests:myRank')}:{' '}
                  <span className={classes.my_rank_value}>
                    {myRank?.rank || '-'}
                  </span>
                </span>
              </div>

              <PrizePool event={event} displayResolution />
            </>
          )}
        </Card>
      </div>
    </div>
  );
};
