import {
  EventRewardType,
  SingleEventType,
  humanize,
} from '@xborglabs/ui-shared';
import {
  getJoinStates,
  useEventUserRank,
  useResource,
} from '@xborglabs/ui-shared/dist/client';
import sortBy from 'lodash/sortBy';
import Image from 'next/image';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { match } from 'ts-pattern';

import { Images } from '@/assets/imgs';
import { EventRewardResolution } from '@/modules/event';
import { Card } from '@/modules/shared/components/Atom/Card/Card';
import { Icon } from '@/modules/shared/components/Atom/Icon';

import classes from './PrizePool.module.scss';

export function PrizePool({
  event,
  displayResolution = false,
}: {
  event: SingleEventType;
  displayResolution?: boolean;
}) {
  const { t } = useTranslation('PrizePool');
  const { data: myRank } = useEventUserRank(event.eventId);

  if (!event.rewards || !event.rewards?.length) return null;

  const joinStates = getJoinStates(event);
  const sortedRewards = sortBy(
    event.rewards,
    (r) => r.resolutionValueRange.min,
  );

  return (
    <div className={classes.prizepool_container}>
      {sortedRewards.map((reward, index) => {
        const isWinnable = myRank?.rank
          ? myRank.rank <= reward.resolutionValueRange.max
          : false;

        return (
          <div
            className={`${classes.prize_container} ${
              isWinnable ? classes.winnable : ''
            }`}
            key={index}
          >
            <Card
              className={`${classes.prize} ${
                isWinnable ? classes.winnable : ''
              }`}
            >
              <Reward communityId={event.containerId} reward={reward} />
            </Card>

            {displayResolution && (
              <EventRewardResolution
                resolution={reward.resolutionType as any}
                resolutionValueRange={reward.resolutionValueRange}
                isWinnable={isWinnable}
                isEnded={Boolean(joinStates?.eventEnded)}
              />
            )}
          </div>
        );
      })}
    </div>
  );
}

function Reward({
  communityId,
  reward,
}: {
  communityId: string;
  reward: EventRewardType;
}) {
  const { data: resource, isLoading } = useResource(
    reward.rewardArgs?.resourceTypeId,
  );
  const { t } = useTranslation(['events']);

  if (reward.reward?.name === 'credit_resource' && isLoading) {
    return <Skeleton width={64} />;
  }

  return (
    <div className={`${classes.exp} lexend-body-s`}>
      {match(reward.reward?.name)
        .with('credit_resource', () => {
          const amount = Number(reward.rewardArgs?.amount);
          return (
            <>
              {resource?.images?.resourceImageUrl ? (
                <Image
                  src={resource.images.resourceImageUrl}
                  width={64}
                  height={64}
                  alt={resource.name}
                  className="object-fit-contain"
                />
              ) : (
                <Image
                  src={Images.points.points_blue}
                  width={64}
                  height={64}
                  alt="xp"
                  className="object-fit-contain"
                />
              )}

              {typeof amount === 'number' && <div>{humanize(amount)}</div>}
            </>
          );
        })
        .with('assign_discord_role', () => {
          return (
            <>
              <Icon.discord size={64} color="#4e32ff" />

              <div>{reward.rewardArgs?.roleName}</div>
            </>
          );
        })
        .with('give_assets', () => {
          return (
            <>
              <Image
                src={Images.points.wearable}
                alt="wearable"
                width={64}
                height={64}
                className="object-fit-contain"
              />

              <div>{t('events:wearable')}</div>
            </>
          );
        })

        .otherwise(() => null)}
    </div>
  );
}
