import { SingleEventType } from '@xborglabs/ui-shared';
import { useTranslation } from 'react-i18next';

import { Card } from '@/modules/shared/components/Atom/Card/Card';
import { Parser } from '@/modules/shared/components/Organism/RichText';

import classes from './AboutEvent.module.scss';

export function AboutEvent({ event }: { event?: SingleEventType }) {
  const { t } = useTranslation(['events']);
  return (
    <Card variant="overlay" className={classes.container}>
      <div className={classes.heading}>{t('about')}</div>
      <div className={classes.body}>
        <Parser content={event?.description} />
      </div>
    </Card>
  );
}
