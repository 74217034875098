import { useSyncEvent } from '@xborglabs/ui-shared/dist/client';
import { useTranslation } from 'next-i18next';
import { useEffect } from 'react';

import { LOCAL_STORAGE_VALUE } from '@/apis';
import { toast } from '@/lib/toastify';
import {
  BUTTON_SIZE,
  BUTTON_VARIANT,
  Button,
} from '@/modules/shared/components/Atom/Buttons';
import { Icon } from '@/modules/shared/components/Atom/Icon';
import { LoadingSpinner } from '@/modules/shared/components/Template/Loader';

export type EventQuestsSyncProps = {
  eventId: string;
  refetch: () => any;
  onSyncChange: (value: boolean) => any;
  isSyncing: boolean;
};

export const EventQuestSync = ({
  eventId,
  refetch,
  isSyncing,
  onSyncChange,
}: EventQuestsSyncProps) => {
  const { t } = useTranslation(['quests', 'errors']);
  const runSync = useSyncEvent(eventId);

  useEffect(() => {
    const key = `${LOCAL_STORAGE_VALUE.QUEST_EVENT_SYNC_TIMER}:${eventId}`;
    const lastRan = localStorage.getItem(key);
    if (lastRan) {
      const lastRanDate = new Date(lastRan);
      const now = new Date();
      if (now.getTime() - lastRanDate.getTime() < 5 * 60 * 1000) {
        return; // Exit if last run was less than 5 minutes ago
      }
    }

    onSync();
  }, [eventId]);

  const onSync = () => {
    if (isSyncing) {
      return;
    }

    onSyncChange(true);
    runSync
      .mutateAsync()
      .then(async () => {
        await refetch();
        toast.success(t('quests:questsSynced'));
      })
      .catch((err) => {
        toast.error(err?.name ?? t('errors:unexpected_error'));
      })
      .finally(async () => {
        onSyncChange(false);
      });
  };

  return (
    <Button
      onClick={onSync}
      variant={BUTTON_VARIANT.OUTLINE}
      size={BUTTON_SIZE.SMALL}
    >
      <div className={'flex gap-small'} style={{ alignItems: 'center' }}>
        {isSyncing ? (
          <LoadingSpinner size={'xsmall'} />
        ) : (
          <Icon.reload size={14} color={'white'} />
        )}
        {isSyncing ? t('quests:syncingStats') : t('quests:refreshStats')}
      </div>
    </Button>
  );
};
