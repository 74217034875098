import {
  ParticipantSyncStatusType,
  QUEST_EVENT_TYPE,
} from '@xborglabs/ui-shared';
import { EventDetail, EventQuestTags } from '@xborglabs/ui-shared/dist/client';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';

import { EventQuestSync } from '@/modules/event/components/EventQuests/EventQuestSync';
import { QuestsList } from '@/modules/quests/components/QuestsList/QuestsList';
import { Tag } from '@/modules/shared/components/Atom/Tag';

export type EventQuestsProps = {
  eventId: string;
};

export const EventQuests = ({ eventId }: EventQuestsProps) => {
  const { t } = useTranslation(['quests', 'errors']);
  const [filter, setFilter] = useState<EventQuestTags>('ongoing');
  const [syncing, setSyncing] = useState(false);

  return (
    <EventDetail eventId={eventId} filter={filter}>
      {({ event, refetch, joinStates, questStatus }) => {
        const syncStatus = event?.participation?.syncStatus;

        const showSyncButton =
          joinStates?.isLive &&
          event?.type === QUEST_EVENT_TYPE.SCORE &&
          syncStatus !== ParticipantSyncStatusType.SYNCED;

        return (
          <div className="event-quests">
            <div className={'flex between w-full'}>
              <div
                className={`flex middle tags-list ${
                  !showSyncButton ? 'w-full' : ''
                }`}
              >
                <Tag
                  label={t('quests:ongoing')}
                  slug="ongoing"
                  active={filter === 'ongoing'}
                  onClick={(tag) => setFilter(tag as EventQuestTags)}
                />
                <Tag
                  label={t('quests:completed')}
                  slug="completed"
                  active={filter === 'completed'}
                  onClick={(tag) => setFilter(tag as EventQuestTags)}
                />
              </div>

              {showSyncButton && (
                <EventQuestSync
                  onSyncChange={setSyncing}
                  isSyncing={
                    syncing || syncStatus === ParticipantSyncStatusType.SYNCING
                  }
                  refetch={refetch}
                  eventId={event.eventId}
                />
              )}
            </div>
            <QuestsList
              list={event?.quests}
              status={questStatus}
              emptyMessage={t('errors:globalErrors.quests_list_empty')}
              actionDisabled={event?.type === QUEST_EVENT_TYPE.SCORE}
            />
          </div>
        );
      }}
    </EventDetail>
  );
};
