import {
  EventWithCommunityAndQuests,
  getEventPrimaryResource,
} from '@xborglabs/ui-shared';
import {
  useQuestEventLeaderboard,
  useResource,
} from '@xborglabs/ui-shared/dist/client';
import { useState } from 'react';
import ReactPaginate from 'react-paginate';

import { EventRankContainer } from '@/modules/event';
import { Leaderboard } from '@/modules/leaderboard';
import { Card } from '@/modules/shared/components/Atom/Card/Card';

export type EventLeaderboardProps = {
  event: EventWithCommunityAndQuests;
};

export const EventLeaderboard = ({ event }: EventLeaderboardProps) => {
  const limit = 25;
  const [page, setPage] = useState(1);
  const { data, isLoading, isError, refetch } = useQuestEventLeaderboard(
    event?.eventId,
    limit,
    page,
  );

  const { data: resourceType } = useResource(
    getEventPrimaryResource(event), // TODO this function should be removed once the event api exposes its own primary resource.
  );

  return (
    <div className="event-leaderboard">
      <EventRankContainer event={event} resourceType={resourceType} />
      <Card variant="overlay" className="flex column expand gap-normal">
        <Leaderboard
          data={data?.users}
          isError={isError}
          isLoading={isLoading}
          refetch={refetch}
          resourceType={resourceType}
        />
        {data ? (
          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            onPageChange={({ selected }) => {
              setPage(selected + 1);
            }}
            pageRangeDisplayed={5}
            pageCount={data.total / limit}
            previousLabel="<"
            renderOnZeroPageCount={null}
            className="page-pagination lexend-body-xs2"
          />
        ) : null}
      </Card>
    </div>
  );
};
