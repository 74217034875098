import { SingleEventType, humanize } from '@xborglabs/ui-shared';
import { useResource } from '@xborglabs/ui-shared/dist/client';
import groupBy from 'lodash/groupBy';
import sumBy from 'lodash/sumBy';
import uniqBy from 'lodash/uniqBy';
import Image from 'next/image';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';

import { Images } from '@/assets/imgs';
import { Icon } from '@/modules/shared/components/Atom/Icon';

import classes from './EventRewards.module.scss';

export function EventRewards({ event }: { event: SingleEventType }) {
  const pairs = useMemo(() => {
    const grouped = groupBy(event.rewards, 'reward.name');
    return Object.entries(grouped).map(([key, rewards]) => ({
      name: key,
      reward: uniqBy(rewards, 'rewardArgs.resourceTypeId')?.[0],
      sum: sumBy(rewards, (r) =>
        r.rewardArgs?.amount ? Number(r.rewardArgs.amount) : 0,
      ),
    }));
  }, [event.rewards]);

  return (
    <div className="flex gap-normal middle">
      {pairs.map((meta, index) => (
        <div className="flex middle gap-small" key={meta.reward.rewardId}>
          <Reward
            key={index}
            communityId={event.containerId}
            resourceTypeId={meta.reward.rewardArgs.resourceTypeId}
            type={meta.name}
            sum={meta.sum}
          />
        </div>
      ))}
    </div>
  );
}

function Reward({
  resourceTypeId,
  communityId,
  type,
  sum,
}: {
  communityId: string;
  resourceTypeId: string;
  type: string;
  sum?: number;
}) {
  const { data: resource, isLoading } = useResource(resourceTypeId);
  const { t } = useTranslation(['events']);
  const defaultImage = {
    credit_resource: (
      <Image
        src={Images.points.points_blue}
        alt="points"
        width={16}
        height={16}
        className="object-fit-contain"
      />
    ),
    give_assets: (
      <Image
        src={Images.points.wearable}
        alt="wearable"
        width={16}
        height={16}
        className="object-fit-contain"
      />
    ),
    assign_discord_role: <Icon.discord size={16} className={classes.discord} />,
  }[type] ?? (
    <Image
      src={Images.points.points_blue}
      alt="points"
      width={32}
      height={32}
      className="object-fit-contain"
    />
  );

  if (type === 'credit_resource' && isLoading) {
    return <Skeleton width={56} />;
  }

  return (
    <div className="flex middle gap-small lexend-heading-s">
      {type === 'give_assets' && t('events:wearable')}
      {type === 'assign_discord_role' && t('events:role')}
      {type === 'credit_resource' &&
        typeof sum === 'number' &&
        `${humanize(sum)} `}
      {resource?.images?.resourceImageUrl ? (
        <Image
          src={resource.images.resourceImageUrl}
          width={16}
          height={16}
          alt={resource.name}
          className="object-fit-contain"
        />
      ) : (
        <>{defaultImage}</>
      )}
    </div>
  );
}
