import { SingleEventType } from '@xborglabs/ui-shared';
import Image from 'next/image';

import { Images } from '@/assets/imgs';
import { BackButton } from '@/modules/shared/components/Atom/Buttons/BackButton';

import classes from './EventHead.module.scss';

export type EventHeadProps = {
  event: SingleEventType | undefined;
};

export const EventHead = ({ event }: EventHeadProps) => {
  const coverImage = event?.images?.coverImageUrl ?? Images.events.cover;

  return (
    <div className={classes.event_header}>
      <Image
        fill
        fetchPriority="high"
        className={classes.event_header_bg}
        src={coverImage}
        alt={event?.title ?? ''}
      />

      <div className="flex">
        <BackButton />
      </div>
    </div>
  );
};
