import {
  QUEST_REWARD_NAMES,
  QUEST_STATUS_TYPE,
  QuestDetailsType,
} from '@xborglabs/ui-shared';
import { useRef } from 'react';
import { P, match } from 'ts-pattern';

import { SingleQuest } from '@/modules/quests/components/SingleQuest/SingleQuest';
import {
  ListError,
  questErrorImage,
} from '@/modules/shared/components/Template/Errors';

import shimmerClasses from '@/styles/animations/shimmer.module.scss';

import { Stat } from '../QuestStats/Stats';

export type QuestsListProps = {
  status?: QUEST_STATUS_TYPE;
  list?: QuestDetailsType[];
  emptyMessage: string;
  actionDisabled?: boolean;
};

export const QuestsList = ({
  list,
  status,
  emptyMessage,
  actionDisabled,
}: QuestsListProps) => {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <div className="quests-list flex flex-wrap" ref={ref}>
      {match([status, list?.length])
        .with([QUEST_STATUS_TYPE.FULFILLED, 0], () => (
          <ListError text={emptyMessage} image={questErrorImage} />
        ))
        .with([QUEST_STATUS_TYPE.FULFILLED, P.number], () => (
          <QuestGrid quests={list!} actionDisabled={actionDisabled} />
        ))
        .with([QUEST_STATUS_TYPE.PENDING, P.select()], QuestListLoadingSkeleton)
        .otherwise(() => null)}
    </div>
  );
};

function QuestListLoadingSkeleton() {
  return (
    <>
      <div className="quest-container">
        <SingleQuest className={shimmerClasses['animate-shimmer']} />
      </div>
      <div className="quest-container">
        <SingleQuest className={shimmerClasses['animate-shimmer']} />
      </div>
      <div className="quest-container">
        <SingleQuest className={shimmerClasses['animate-shimmer']} />
      </div>
      <div className="quest-container">
        <SingleQuest className={shimmerClasses['animate-shimmer']} />
      </div>
    </>
  );
}

function QuestGrid({
  quests,
  actionDisabled,
}: {
  quests: QuestDetailsType[];
  actionDisabled?: boolean;
}) {
  return (
    <>
      {quests.map((item) => (
        <div className="quest-container" key={item.questId}>
          <SingleQuest
            data={item}
            disabled={actionDisabled}
            Icon={match(item.reward.name)
              .with(QUEST_REWARD_NAMES.PROGRESS, () => (
                <Stat metrics={item.metrics} defaultStatText="">
                  {({ items }) => (
                    <>
                      {items.map((item) => (
                        <p className="lexend-body-m font-bold" key={item.title}>
                          {item.value}
                        </p>
                      ))}
                    </>
                  )}
                </Stat>
              ))
              .otherwise(() => null)}
          />
        </div>
      ))}
    </>
  );
}
