import { useTranslation } from 'react-i18next';
import { match } from 'ts-pattern';
import classes from './EventRewardResolution.module.scss';

export function EventRewardResolution({
  resolution,
  resolutionValueRange,
  isWinnable,
  isEnded,
}: {
  resolution: 'ABSOLUTE' | 'RELATIVE' | string;
  resolutionValueRange: { min: number; max: number };
  isWinnable: boolean;
  isEnded: boolean;
}) {
  const { t } = useTranslation(['events']);

  return match({
    isWinnable,
    isEnded,
  })
    .with(
      {
        isWinnable: true,
        isEnded: true,
      },
      () => (
        <div
          className={`${classes.price_resolution} ${classes.winnable} lexend-body-s font-bold`}
        >
          {t('events:rewardReceivedAutomatically')}
        </div>
      ),
    )
    .otherwise(() => (
      <div
        className={`${classes.price_resolution} lexend-body-s font-bold ${
          isWinnable ? classes.winnable : ''
        }`}
      >
        {t('top')}{' '}
        {resolutionValueRange?.min + ' - ' + resolutionValueRange?.max}{' '}
        {resolution === 'RELATIVE' ? '%' : ''}
      </div>
    ));
}
