import { getJoinStates } from '@xborglabs/ui-shared/dist/client';
import { useTranslation } from 'next-i18next';
import { match } from 'ts-pattern';
import classes from './EventStatus.module.scss';

export type EventStatusProps = {
  status?: ReturnType<typeof getJoinStates>;
  isLive?: boolean;
  hasEnded?: boolean;
};

export const EventStatus = ({ status, isLive }: EventStatusProps) => {
  const { t } = useTranslation(['events']);

  const getStatus = () => {
    return match(status)
      .with(
        {
          eventEnded: false,
          eventStarted: true,
        },
        () => t('events:status.isLive'),
      )
      .with(
        {
          eventEnded: true,
        },
        () => t('events:status.completed'),
      )
      .with(
        {
          eventStarted: false,
          isRegistrationOpen: true,
        },
        () => t('events:status.notStarted'),
      )
      .otherwise(() => null);
  };

  return (
    <div className={classes.event_status}>
      <span className={`${classes.status} ${isLive ? classes['live'] : ''}`}>
        {getStatus()}
      </span>
    </div>
  );
};
