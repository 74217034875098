import {
  QUEST_EVENT_TYPE,
  SingleEventType,
  getDate,
  nFormatter,
} from '@xborglabs/ui-shared';
import {
  getJoinStates,
  useCommunityDetail,
} from '@xborglabs/ui-shared/dist/client';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import Link from 'next/link';
import Skeleton from 'react-loading-skeleton';

import { Images } from '@/assets/imgs';
import { EventRewards, EventStatus } from '@/modules/event';
import { Card, GlassCard } from '@/modules/shared/components/Atom/Card/Card';
import { GLOBAL_ROUTES } from '@/routes';

import { Icon } from '@/modules/shared/components/Atom/Icon';
import classes from './SingleEvent.module.scss';

export type SingleEventProps = {
  data?: SingleEventType;
  dateVisible?: boolean;
  participantsVisible?: boolean;
  communityLogoVisible?: boolean;
};

export const SingleEvent = ({
  data,
  dateVisible = true,
  participantsVisible = true,
  communityLogoVisible = false,
}: SingleEventProps) => {
  const { t } = useTranslation(['events', 'globals']);
  const { data: community } = useCommunityDetail(data?.containerId);
  if (!data || !community) {
    return <SingleEventSkeleton />;
  }
  const joinStates = getJoinStates(data);
  const href = GLOBAL_ROUTES.COMMUNITY_EVENT_DETAILS(
    community.slug,
    data.eventId,
  );
  const startDate = t('{{val, datetime}}', {
    val: getDate(data.startDate),
    formatParams: {
      val: {
        hour: 'numeric',
        minute: 'numeric',
        month: 'short',
        day: 'numeric',
      },
    },
  });
  const endDate = t('{{val, datetime}}', {
    val: getDate(data.endDate),
    formatParams: {
      val: {
        hour: 'numeric',
        minute: 'numeric',
        month: 'short',
        day: 'numeric',
      },
    },
  });
  const participantsLabel = `${nFormatter(data.totalParticipants ?? 0, 2) ?? 0}/
                  ${nFormatter(data.maxParticipants ?? 0, 2)}`;

  return (
    <Link href={href} className={classes.event_link}>
      <Card className={classes.event_card} spacing="none">
        <div className={classes.image_cover}>
          <Image
            src={data.images?.coverImageUrl ?? Images.events.cover}
            alt=""
            width={389}
            height={252}
            className={classes.cover}
          />
          {communityLogoVisible ? (
            <Image
              src={
                community.content?.images.logoImageUrl ??
                Images.communityview.logo
              }
              alt=""
              width={48}
              height={48}
              className="cover__logo"
            />
          ) : null}
          <GlassCard
            roundness="none"
            className={classes.event_rewards_container}
          >
            <EventRewards event={data} />
            {participantsVisible && (
              <div className={classes.participants}>
                <div>{`${participantsLabel} ${t('events:participants')}`}</div>
              </div>
            )}
          </GlassCard>
        </div>

        <div className={classes.event_body}>
          <div className={classes.event_body_header}>
            <EventStatus
              status={joinStates}
              isLive={joinStates?.eventStarted && !joinStates?.eventEnded}
            />
            {dateVisible && (
              <div className={classes.event_dates}>
                <i className="date-string">{`${startDate} - ${endDate}`}</i>
              </div>
            )}
          </div>

          <div className={classes.event_title_container}>
            <h4 className={classes.event_title}>{data.title}</h4>
            {data.type === QUEST_EVENT_TYPE.SCORE && (
              <div className={classes.score_based}>
                <Icon.target size={16} color="white" />
                {t('scoreBased')}
              </div>
            )}
          </div>
        </div>
      </Card>
    </Link>
  );
};

function SingleEventSkeleton() {
  return (
    <div className="single-event">
      <div className="observer flex column">
        <Skeleton />
        <h3 className="event-title lexend-heading">
          <Skeleton />
        </h3>

        <div className="event-details flex between middle">
          <div className="flex column">
            <Skeleton />

            <div className="date lexend-body-xs1">
              <Skeleton />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
