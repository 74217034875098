import { getDate } from '@xborglabs/ui-shared';
import { useEffect, useState } from 'react';

export type CountdownType = {
  date: Date;
  className?: string;
  type?: COUNTDOWN_TYPE;
  displaySecs?: boolean;
  displayMins?: boolean;
};

export enum COUNTDOWN_TYPE {
  FULL = 'FULL',
  FULL_LONG = 'FULL_LONG',
  MINUTE = 'MINUTE',
}

export type CountdownTimeType = {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
};

export const Countdown = ({
  date,
  className,
  type = COUNTDOWN_TYPE.FULL,
  displaySecs = false,
  displayMins = true,
}: CountdownType) => {
  const [time, setTime] = useState<CountdownTimeType>({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    // Set the date we're counting down to
    const countDownDate = date.getTime();

    // Update the count down every 1 second
    const x = setInterval(function () {
      // Get today's date and time
      const now = getDate().getTime();

      // Find the distance between now and the count down date
      const distance = countDownDate - now;

      // Time calculations for days, hours, minutes and seconds
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      if (distance < 0) {
        clearInterval(x);
        setTime({
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0,
        });
      } else {
        setTime({
          days,
          hours,
          minutes,
          seconds,
        });
      }
    }, 1000);

    return () => clearInterval(x);
  }, [date]);

  // Make arrays and make sure it always have at least
  // 2 numbers in it with 0 in front
  const days = time.days.toString().padStart(2, '0');
  const hours = time.hours.toString().padStart(2, '0');
  const minutes = time.minutes.toString().padStart(2, '0');
  const seconds = time.seconds.toString().padStart(2, '0');

  return (
    <div className={`${className ?? ''} countdown flex`}>
      {type !== COUNTDOWN_TYPE.MINUTE && (
        <>
          <div className="countdown-section flex middle">
            <div className="countdown-numbers">
              <span>{days}</span>
            </div>
            <div className="countdown-title">
              {type == COUNTDOWN_TYPE.FULL ? 'D' : 'Days'}
            </div>
          </div>
          <div className="countdown-section flex middle">
            <div className="countdown-numbers">
              <span>{hours}</span>
            </div>
            <div className="countdown-title">
              {type == COUNTDOWN_TYPE.FULL ? 'H' : 'Hrs'}
            </div>
          </div>

          {displayMins ? (
            <div className="countdown-section flex middle">
              <div className="countdown-numbers">
                <span>{minutes}</span>
              </div>
              <div className="countdown-title">
                {type == COUNTDOWN_TYPE.FULL ? 'M' : 'Min'}
              </div>
            </div>
          ) : null}
          {displaySecs && (
            <div className="countdown-section flex middle">
              <div className="countdown-numbers">
                <span>{seconds}</span>
              </div>
              <div className="countdown-title">
                {type == COUNTDOWN_TYPE.FULL ? 'S' : 'Secs'}
              </div>
            </div>
          )}
        </>
      )}
      {type == COUNTDOWN_TYPE.MINUTE && (
        <div className="countdown-section flex middle">
          <div className="countdown-numbers">
            {displayMins ? <span>{minutes}</span> : null}
            <span>{seconds}</span>
          </div>
        </div>
      )}
    </div>
  );
};
