import { QueryStatus } from '@tanstack/react-query';
import { SingleEventType } from '@xborglabs/ui-shared';
import { P, match } from 'ts-pattern';

import { SingleEvent } from '@/modules/event';
import {
  ListError,
  eventsErrorImage,
} from '@/modules/shared/components/Template/Errors';

import { Card } from '@/modules/shared/components/Atom/Card/Card';
import classes from './EventsList.module.scss';

export type EventsListProps = {
  status: QueryStatus;
  rejectedMessage: string;
  emptyMessage: string;
  events?: SingleEventType[];
  offset?: number;
  total?: number;
  onReload?: () => void;
};

export const EventsList = ({
  events,
  status,
  rejectedMessage,
  emptyMessage,
  onReload,
}: EventsListProps) => {
  return (
    <Card className={classes.events_list_container}>
      {match([status, events?.length])
        .with(['success', 0], () => (
          <ListError text={emptyMessage} image={eventsErrorImage} />
        ))
        .with(
          ['success', P.number],
          () =>
            events?.map((event) => (
              <SingleEvent data={event} key={event.eventId} />
            )),
        )
        .with(['loading', P.select()], () => (
          <>
            <SingleEvent />
            <SingleEvent />
            <SingleEvent />
          </>
        ))
        .with(['error', P.select()], () => (
          <ListError
            text={rejectedMessage}
            onReload={onReload}
            image={eventsErrorImage}
          />
        ))
        .otherwise(() => null)}
    </Card>
  );
};
